// import "@fortawesome/fontawesome-svg-core/styles.css"
import React from 'react'
import { SettingsProvider } from "./src/context/SettingsContext"
import { CartItemsProvider } from "./src/context/CartItemsContext"
export const wrapRootElement = ({ element }) => (
  <CartItemsProvider>{element}</CartItemsProvider>
)
export const wrapPageElement = ({ element }) => (
  <SettingsProvider>{element}</SettingsProvider>
)
